import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { fetchMembersDisenroll, updateMembersDisenroll, fetchProgramList, fetchMembersDisenrollNew } from '../../../services/medengage/Service';
import { CustomLoader } from '../../utils/CommonLibrary';
import { useNavigate } from 'react-router-dom';
import { exportTableData, LoaderLoader } from '../../utils/CommonLibrary';
import BackendDrivenDataTable from '../../cn/components/common/BackendDrivenDataTable';
import { Modal } from 'react-bootstrap';

function NewRequestTablev2() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProgram, setSelectedProgram] = useState('');
    const [selectedCondition, setSelectedCondition] = useState('');

    const [membersDisenrollList, setMembersDisenrollList] = useState([]);
    const [data, setData] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [conditionList, setConditionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("disEnrollRequestedDate");
    const [sortDirection, setSortDirection] = useState('desc');
    const [download, setDownload] = useState('N');
    const navigate = useNavigate();


    const formObj = {
        id: '',
        action: '',
        remarks: ''
    }
    const [formData, setFormData] = useState(formObj);
    const [showDisEnrollmentModal, setShowDisEnrollmentModal] = useState(false);
    const [disEnrollmentError, setDisEnrollmentError] = useState("");

    useEffect(() => {
        fetchMembersDisenrollList();
    }, [page, perPage, sortColumn, sortDirection, searchQuery, selectedProgram, selectedCondition, download]);

    useEffect(() => {
        fetchDropDownList();
    }, []);

    const fetchDropDownList = async () => {
        await fetchProgramList()
            .then((response) => {
                setProgramList(response.data.data.programList);
                setConditionList(response.data.data.conditionList);
            });
    }

    const fetchMembersDisenrollList = async () => {
        try {
            setIsLoading(true);
            fetchMembersDisenrollNew(searchQuery, selectedProgram, selectedCondition,
                page, perPage, sortColumn, sortDirection, download)
                .then((response) => {
                    if (response.data.code == 200) {
                        if (download == "N") {
                            setData(response.data.data.logs);
                            setTotalRows(response.data.data.totalRows);
                        } else {
                            handleExport(response.data.data.logs);
                            setDownload('N');
                        }
                    }
                    setIsLoading(false);
                });
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    };
    /*const fetchMembersDisenrollList = async () => {
        setIsLoading(true);
        try {
            const response = await fetchMembersDisenroll();

            if (response.data.status === 'success') {
                setMembersDisenrollList(response.data.data.filter(data => data.requestStatus === 'Pending'));
            } else {
                setMembersDisenrollList([]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }*/

    const formatDate = (inputDate) => {
        if (!inputDate) return '';
        const date = new Date(inputDate);

        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

        return formattedDate;
    }

    const columns = [
        {
            name: 'Request Date',
            selector: row => row.disEnrollRequestedDate,
            format: row => row.disEnrollRequestedDate,
            sortable: true,
        },
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Main Membership ID',
            selector: row => row.mainMembershipNo,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: false,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: false,
        },
        {
            name: 'Mobile No',
            selector: row => row.mobile,
            sortable: false,
        },
        {
            name: 'Program',
            selector: row => row.carePrograms,
            sortable: false,
        },
        {
            name: 'Condition',
            selector: row => row.chronicConditions,
            sortable: false,
        },
        {
            name: 'Enrollment Date',
            selector: row => row.enrollmentTime,
            format: row => row.enrollmentTime,
            sortable: true,
        },
        {
            name: 'Care Navigator Name',
            selector: row => row.careName,
            sortable: false,
        },
        {
            name: 'Supervisor',
            selector: row => row.supervisorName,
            sortable: false,
        },
        {
            name: 'Reason',
            selector: row => row.reason,
            sortable: false,
        },
        {
            name: 'Care Navigator Remarks',
            selector: row => row.disEnrollmentNote,
            sortable: false,
            cell: row => (
                <div dangerouslySetInnerHTML={{ __html: row.disEnrollmentNote }} />
            ),
        },
        {
            name: 'Dis-EnrollmentType',
            id: 'disEnrollmentType',
            selector: row => row.disEnrollmentType,
            format: row => row.disEnrollmentType,
            sortable: false,
        },
        {
            name: 'Action',
            cell: row => (
                <div>
                    <a href="#" className="btn-approve" onClick={() => openRemarksModal(row.logId, 'Approve')}>Approve</a> / <a href="#" className="btn-reject" onClick={() => openRemarksModal(row.logId, 'Reject')}>Reject</a>
                </div>
            ),
        },
    ];

    const openRemarksModal = (logId, action) => {
        setFormData({
            ...formData,
            id: logId,
            action: action,
            remarks: ''
        });
        setShowDisEnrollmentModal(true);
    }

    const handleStatusUpdateNew = async () => {
        setIsLoading(true);
        try {
            const payload = {
                logId: formData.id,
                requestStatus: (formData.action === 'Approve' ? 'Approved' : 'Rejected'),
                remarks: formData.remarks
            }
            const response = await updateMembersDisenroll(payload);

            if (response.data.status === 'success') {
                setFormData(formObj);
                setShowDisEnrollmentModal(false);
                setMembersDisenrollList((prev) => prev.filter((data) => data.logId !== formData.id));
                setIsLoading(false);
                fetchMembersDisenrollList();
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    //console.log(formData);

    const handleClear = () => {
        setSearchQuery('');
        setSelectedProgram('');
        setSelectedCondition('');
    };

    const newFilteredData = membersDisenrollList.filter(user => {
        const matchesSearchQuery = user.memberName.toLowerCase().includes(searchQuery.toLowerCase()) || user.membershipNo == searchQuery;

        const matchesProgram = selectedProgram === 'All' || user.carePrograms.toLowerCase() === selectedProgram.toLowerCase();
        const matchesCondition = selectedCondition === 'All' || user.chronicConditions.toLowerCase() === selectedCondition.toLowerCase();

        return matchesSearchQuery && matchesProgram && matchesCondition;
    });


    const handleExport = (data) => {

        const modifiedData = data.map(item => {
            return {
                'Request Date': item.disEnrollRequestedDate,
                'Member ID': item.membershipNo,
                'Main Membership ID': item.mainMembershipNo,
                'Member Name': item.memberName,
                'Email': item.email,
                'Mobile No': item.mobile,
                'Program': item.carePrograms,
                'Condition': item.chronicConditions,
                'Enrollment Date': item.enrollmentTime,
                'Care Navigator Name': item.careName,
                'Supervisor Name': item.supervisorName,
                'Reason': item.reason,
                'Care Navigator Remarks': item.disEnrollmentNote,
                'Dis-EnrollmentType': item.disEnrollmentType,
            };
        });

        modifiedData.forEach(function (v) { delete v.approvedBy; delete v.approvedDate; delete v.logId; delete v.mobile; delete v.requestStatus; delete v.userId; });

        exportTableData("De-EnrollNewRequest", modifiedData);
    };

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by Member ID"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedProgram}
                            onChange={e => setSelectedProgram(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="">All Programs</option>
                            {programList.map(option => (
                                <option key={option.programId} value={option.programId}>{option.programName}</option>
                            ))}

                        </select>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedCondition}
                            onChange={e => setSelectedCondition(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="">All Conditions</option>
                            {conditionList.map(option => (
                                <option key={option.conditionId} value={option.conditionId}>{option.conditionName}</option>
                            ))}

                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={() => setDownload('Y')} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                    </div>
                </div>
            </div>
            {
                isLoading ? <CustomLoader /> :
                    <BackendDrivenDataTable
                        data={data}
                        loading={isLoading}
                        totalRows={totalRows}
                        page={page}
                        perPage={perPage}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        filterText={searchQuery}
                        columns={columns}
                        fetchTableData={fetchMembersDisenrollList}
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        setPerPage={setPerPage}
                    />
            }
            <Modal id="disEnrollment" centered show={showDisEnrollmentModal} onHide={() => setShowDisEnrollmentModal(false)} size="" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>Member De-enrollment request</h1>
                </Modal.Header>
                <Modal.Body>
                    {
                        <div className='row'>
                            <div className='col-12 mb-3'>
                                <label>Remarks:</label>
                                <textarea name="remarks" className='form-control control-input-ui' onChange={(e) => setFormData({
                                    ...formData,
                                    remarks: e.target.value
                                })} value={formData.remarks}></textarea>
                            </div>
                            <div className='col-12 text-center'>
                                <div className={`${disEnrollmentError != "" ? "errorDiv" : "hide"}`}>{disEnrollmentError}</div>
                                <button className="btn btn-primary save-btn me-3" onClick={() => handleStatusUpdateNew()}>{formData.action}</button>
                            </div>
                        </div>

                    }
                </Modal.Body >
            </Modal >
        </>
    );
}

export default NewRequestTablev2;
